@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@layer base {
  @import "base";
}

@layer components {
  @import "components";
}

@layer utilities {
  @import "utilities";
}