@layer components {
  // .button {
  //   @apply border-0 text-white py-2 px-3 bg-primary-600 font-semibold tracking-wide rounded;
  //   &.secondary {
  //     @apply bg-secondary-600;
  //   }
  //   &.tertiary {
  //     @apply bg-tertiary-600
  //   }
  // }

  .gradient {
    background: linear-gradient(90deg, #000 0%, #444 100%);
  }
}